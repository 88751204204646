<template lang="pug">
  div
    v-dialog(v-model='dialog', width='900' scrollable)
      template(v-slot:activator='{ on, attrs }')
        v-btn.fz-14(elevation='0', v-if='type === "new"', color='accent', v-bind='attrs', v-on='on')
          | Новая группа

        tooltip(text='Редактировать группу')
          v-btn(elevation='0', v-if='type !== "new"', fab, x-small, v-bind='attrs', v-on='on')
            v-icon(small) mdi-lead-pencil
      v-card(v-model='dialog')
        v-card-title.headline.grey.lighten-2
          div {{ type === 'new' ? 'Новая группа' : 'Редактирование группы' }}
        v-card-text
          v-row.mt-2
            v-col
              v-text-field(label='Поиск', v-model='search', dense, outlined, hide-details)
            v-col
              v-text-field(
                :label='type === "new" ? "Придумайте название" : "Новое название"',
                v-model='name',
                dense,
                outlined,
                hide-details,
                :disabled="disabled === 'text'"
                @input="onChange"
              )

          v-row(justify='space-between', align='end', v-for='(item, index) in getCampaigns' :key='index')
            v-checkbox.mt-2.ml-2(
              color='accent',
              multiple,
              dense,
              hide-details,
              @change="item"
              v-model='selected'
              :value='item.id',
              :label='item.name',
              :id='index.id',
            ) {{ item.name }}
        v-card-actions
          v-spacer
          v-btn(color='accent', @click='update', elevation='0')
            | {{ type === "new" ? "Создать группу" : "Сохранить изменения" }}
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Tooltip from '@/components/tooltip';
import {EDIT_CAMPAIGN_GROUP, SET_CAMPAIGN_GROUP} from "@/store/const/campaign";

export default {
  name: 'PopupGroup',
  components: {Tooltip},
  props: {
    type: {
      type: String,
      default: 'new',
    },
    groupName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('Campaign', ['getCampaigns']),
  },
  watch: {
    dialog: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.selected = this.getCampaigns
          .filter((a) => a.group === this.groupName)
          .map((a) => {
            return a.id;
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      search: '',
      name: this.groupName,
      dialog: false,
      selected: [],
      disabled: '',
    };
  },
  mounted() {
    this.selected = this.getCampaigns
    .filter((a) => a.group === this.groupName)
    .map((a) => {
      return a.id;
    });
  },
  methods: {
    ...mapActions('Campaign', [SET_CAMPAIGN_GROUP, EDIT_CAMPAIGN_GROUP]),
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
    valid() {
      if (!this.name) {
        this.errorShow('Введите название группы');
        return false;
      }
      if (!this.selected.length) {
        this.errorShow('Выберите хотя бы одну кампанию');
        return false;
      }

      return true;
    },
    async update() {
      if (!this.valid()) return;

      let data = {
        group: this.name,
        campaigns: this.selected,
      };
      if (this.type !== "new" && this.disabled === 'checkbox') {
        await this.SET_CAMPAIGN_GROUP(data);
      } else {
        await this.EDIT_CAMPAIGN_GROUP(data);
      }
      this.dialog = false;
      this.disabled = '';
      await this.$emit('refresh');
    },
    onChange(data) {
      if (this.type !== "new" && typeof data === 'boolean') {
        this.disabled = 'text';
      } else if (this.type !== "new") {
        this.disabled = 'checkbox';
      }
    }
  },
};
</script>

<style scoped></style>
