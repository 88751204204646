<template lang="pug">
	v-container(fluid)
		v-row(align='center', justify='end')
			v-col(cols='6' offset="6")
				.d-flex
					v-text-field(label='Поиск', v-model='search', dense, outlined, hide-details)
					v-btn.ml-4.fz-14(@click='$router.push("/radar/campaign/create/")', elevation='0', color='accent') Новая кампания
					popup-group.ml-4(v-if='getTableData.length', @refresh='refresh()')
		
		v-row(align='center')
			tooltip(text='Сбросить фильтр')
				v-btn.rounded-circle.ml-3.pa-0(@click='refresh', outlined min-width="36" height="36" color='primary')
					v-icon(color='accent' size='20px') mdi-filter-outline
			v-col(cols='3')
				v-select(
					v-model='filter.group',
					:menu-props='{ bottom: true, offsetY: true }',
					label='Группа',
					:items='customGroups',
					item-value='id',
					item-text='label',
					dense,
					outlined,
					clearable,
					hide-details,
					append-icon='mdi-chevron-down'
				)
			v-col(cols='3')
				v-select(
					v-model='filter.status',
					:items='status',
					:menu-props='{ bottom: true, offsetY: true }',
					label='Статус кампании',
					dense,
					outlined,
					hide-details,
					append-icon='mdi-chevron-down'
				)
			v-col(cols='3')
				v-menu(
					ref='menu',
					v-model='menu',
					:close-on-content-click='false',
					transition='scale-transition',
					offset-y,
					min-width='auto'
				)
					v-date-picker(
						header-color='accent',
						v-model='date',
						:max='new Date().toISOString().substr(0, 10)',
						min='1950-01-01',
						@change='save',
						show-adjacent-months,
						range,
						locale='ru-ru',
						first-day-of-week='1'
					)
					template(v-slot:activator='{ on, attrs }')
						v-text-field(
							v-model='dateFormatted',
							label='Статистика за период',
							prepend-icon='mdi-calendar',
							readonly='',
							v-bind='attrs',
							v-on='on',
							dense,
							outlined,
							hide-details
						)
			v-col
				tooltip(text='Применить фильтр')
					v-btn(@click='applyChanges', tile, fab, elevation='0', small, color='accent')
						v-icon
							| check
		
		v-row.mt-5(v-if='!getTableData.length')
			v-skeleton-loader(type='image', height='55', v-if='isLoading')
			.text-center.no-data_background.pa-10(v-if='!isLoading')
				.no-data_text Вы ещё не создали ни одной рекламной кампании
		.mt-5(v-for='(item, index) in getTableData' :key="index")
			v-data-table.v-data-table--custome.v-data-table--bold(
				:headers='headers',
				:items='item.data',
				:items-per-page='item.show * 100',
				@click.native='test($event, item)',
				:hide-default-footer='!item.show || item.data.length < 101',
				:search='search',
				:loading="isLoading",
				loading-text='Данные загружаются, ожидайте',
				no-results-text='Нет кампаний соответствующих требованиям'
			)
				template(v-slot:progress)
					v-progress-linear(color="accent" indeterminate)
				template(v-slot:header.name='{ header }')
					v-row(justify='space-between')
						div
							.stat__title {{ header.text }}
							.stat__total {{ item.groupName === '' ? 'Без группы' : item.groupName }}
						div(align='center')
							.stat__title Количество
							.stat__total {{ counter(item.data) }}
				
				template(v-slot:header.win='{ header }')
					tooltip(text="Количество выходов ролика на рекламной поверхности")
						.stat__title {{ header.text }}
					.stat__total {{ header.func(item.data) }}
				template(v-slot:header.ots='{ header }')
					tooltip(text="Контактировавшая аудитория с рекламным роликом")
						.stat__title {{ header.text }}
					.stat__total {{ header.func(item.data) }}
				template(v-slot:header.grp='{ header }')
					tooltip(text="Суммарный рейтинг, общее количество контактов целевой аудитории с рекламным роликом")
						.stat__title {{ header.text }}
					.stat__total {{ header.func(item.data) }}
				template(v-slot:header.trp='{ header }')
					tooltip(text="Целевой рейтинг, контакты целевой аудитории с рекламным роликом")
						.stat__title {{ header.text }}
					.stat__total {{ header.func(item.data) }}
				template(v-slot:header.budget='{ header }')
					tooltip(text="Потраченная сумма от бюджета, выделенного на рекламную кампанию")
						.stat__title {{ header.text }}
					.stat__total {{ header.func(item.data) }}
				template(v-slot:header.balance='{ header }')
					tooltip(text="Оставшаяся сумма от бюджета, выделенного на рекламную кампанию")
						.stat__title {{ header.text }}
					.stat__total {{ header.func(item.data) }}
				template(v-slot:header.actions='{ header }')
					popup-group(v-if='item.groupName' type='edit', :groupName='item.groupName', :items='item.data', @refresh='refresh()')
				
				template(v-slot:item.status='{ item }')
					v-switch(
						v-model='item.statusDisplay()',
						:color='campaignStatusColor(item.status)',
						@click='campaignStatusClick(item)',
						readonly
					)
				template(v-slot:item.approved='{ item }')
					span(v-if='limitTotalBudget(item.id) || limitDayBudget(item.id)')
						tooltip(text='Исчерпан лимит')
							v-icon(color='#F9A825') info_outlined
					span(v-else-if='statusInfo(item.id)')
						tooltip(text='Есть неактивные поверхности')
							v-icon(color='#f30000') info_outlined
				template(v-slot:item.name='{ item }')
					v-tooltip(top)
						template(v-slot:activator='{ on, attrs }')
							.d-inline-block(v-bind='attrs', v-on='on')
								v-btn.v-btn--table-link(
									outlined,
									style='max-width: 210px; display: block',
									small,
									color='primary',
									@click='$router.push("/radar/campaign/" + item.id)'
								)
									span.d-block.text-truncate {{ item.name }}
						span {{ item.name }}
				template(v-slot:item.balance='{ item }')
					span {{ item.balance ? formatNumber(item.balance) : formatNumber(item.balance) }}
				template(v-slot:item.win='{ item }')
					span {{ formatNumber(item.win) }}
				template(v-slot:item.budget='{ item }')
					span {{ formatNumber(item.budget) }}
				template(v-slot:item.ots='{ item }')
					span {{ formatNumber(item.ots)  }}
				template(v-slot:item.grp='{ item }')
					span {{ formatNumber(item.grp) }}
				template(v-slot:item.trp='{ item }')
					span {{ formatNumber(item.trp) }}
				template(v-slot:item.actions='{ item }')
					.d-flex
						tooltip(text='Редактировать кампанию')
							v-btn(
								elevation='0',
								outlined,
								fab,
								x-small,
								@click='$router.push("/radar/campaign/" + item.id + "/edit")',
								color='primary'
							)
								v-icon(small) mdi-lead-pencil
						.d-inline-block
							popup-copy(@update='applyChanges', :name='item.name', :id='item.id')
						.d-inline-block
							popup-archive(@refresh='refresh', :id='item.id')
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import eventPath from '@/mixins/eventPath';
import {formatRquestStatsData} from '@/mixins/date_mixin';
import loading from '@/mixins/loading_mixin';
import {GET_CAMPAIGN_LIST, SET_CAMPAIGN_STATUS, CLEAR_CAMPAIGN_STATE, CLEAR_CAMPAIGNS} from '@/store/const/campaign';
import {GET_INVENTORY_STATUS, CLEAR_INVENTORY_STATUS} from '@/store/const/inventory';
import Tooltip from '@/components/tooltip';
import PopupGroup from '@/components/popup/list-campaigns/popupGroup';
import PopupCopy from '@/components/popup/list-campaigns/copy';
import PopupArchive from '@/components/popup/list-campaigns/archive'

export default {
	name: 'campaigns',
	components: {
		Tooltip,
		PopupGroup,
		PopupCopy,
		PopupArchive
	},
	mixins: [formatRquestStatsData, eventPath, loading],
	data() {
		return {
			filter: {
				group: undefined,
				status: 'all',
				date: [],
			},
			date: [],
			menu: false,
			status: [
				{value: 'all', text: 'Все'},
				{value: 'active', text: 'Активные'},
				{value: 'stopped', text: 'Остановленные'},
				{value: 'paused', text: 'На паузе'},
				{value: 'archived', text: 'В архиве'}
			],
			search: '',
			type: '',
			headers: [
				{
					value: 'status',
					sortable: false,
					width: 40,
				},
				{
					value: 'approved',
					sortable: false,
					width: 78
				},
				{
					text: 'Группа',
					value: 'name',
					width: 320,
					sortable: false,
					align: 'left',
				},
				{
					text: 'Показы',
					value: 'win',
					sortable: false,
					width: 100,
					align: 'left',
					func: (data) => {
						return this.summary('win', data);
					},
				},
				{
					text: 'OTS',
					value: 'ots',
					sortable: false,
					width: 100,
					align: 'left',
					func: (data) => {
						return this.summary('ots', data);
					},
				},
				{
					text: 'GRP',
					value: 'grp',
					width: 100,
					sortable: false,
					align: 'left',
					func: (data) => {
						return this.summary('grp', data);
					},
				},
				{
					text: 'TRP',
					value: 'trp',
					width: 100,
					sortable: false,
					align: 'left',
					func: (data) => {
						return this.summary('trp', data);
					},
				},
				{
					text: 'Расходы',
					value: 'budget',
					width: 100,
					sortable: false,
					align: 'left',
					func: (data) => {
						return this.summary('budget', data);
					},
				},
				{
					text: 'Остаток',
					value: 'balance',
					width: 100,
					sortable: false,
					align: 'left',
					func: (data) => {
						return this.summary('balance', data);
					},
				},
				{
					width: 145,
					text: 'actions',
					value: 'actions',
					sortable: false,
				},
			],
			campaignIDs: []
		};
	},
	computed: {
		...mapGetters('Campaign', ['customGroups', 'getTableData', 'getCampaigns']),
		...mapGetters('Inventory', ['getInventoryStatus']),
		...mapGetters('Agency', ['getAccountData']),
		dateFormatted() {
			let total = ''
			if (!this.date.length) {
				return total
			}
			total += this.date[0] < this.date[1]
				? this.$moment(this.date[0]).format('DD.MM.YYYY')
				: this.$moment(this.date[1]).format('DD.MM.YYYY');
			total += ' - '
			total += this.date[0] < this.date[1]
				? this.$moment(this.date[1]).format('DD.MM.YYYY')
				: this.$moment(this.date[0]).format('DD.MM.YYYY');
			return total;
		},
	},
	async mounted() {
		this.setLoadingActions()
		await this.getData();
		await this.getStatusSurface();
		const data = this.getAccountData.filter(item => this.getCampaigns.find(val => val.owner === item.id))
		data.map(items => {
			if (items.balance < 10000) {
				this.$notify({
					type: 'warning',
					text: `Бюджет приближается к нулю, кампании могут остановиться, баланс равен: ${items.balance}`,
				})
			}
		})
	},
	beforeDestroy() {
		this.CLEAR_INVENTORY_STATUS()
		this.CLEAR_CAMPAIGNS()
		this.CLEAR_CAMPAIGN_STATE()
	},
	methods: {
		...mapActions('Campaign', [GET_CAMPAIGN_LIST, SET_CAMPAIGN_STATUS]),
		...mapActions('Inventory', [GET_INVENTORY_STATUS]),
		...mapMutations('Inventory', [CLEAR_INVENTORY_STATUS]),
		...mapMutations('Campaign', [CLEAR_CAMPAIGNS, CLEAR_CAMPAIGN_STATE]),
		setLoadingActions() {
			this.actions = [
				GET_CAMPAIGN_LIST,
				SET_CAMPAIGN_STATUS,
				GET_INVENTORY_STATUS
			]
		},
		async campaignStatusClick(item) {
			switch (item.status) {
				case 'active':
					item.status = 'stopped';
					break;
				case 'stopped':
					item.status = 'paused';
					break;
				case 'paused':
					item.status = 'stopped';
					break;
			}
			await this.SET_CAMPAIGN_STATUS({
				calendar_enabled: false,
				id: item.id,
				status: item.status,
			})
			if (this.isError(SET_CAMPAIGN_STATUS)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: 'Не удалось сменить статус',
				});
				return;
			}
			this.$notify({
				type: 'success',
				title: 'Успешно',
				text: 'Статус кампании успешно изменён',
			});
		},
		campaignStatusColor(val) {
			switch (val) {
				case 'active':
					return '#4A55B0';
				case 'stopped':
					return '#4A55B0';
				case 'paused':
					return '#ffa72d';
			}
		},
		async applyChanges() {
			let data = {
				group: this.filter.group,
				status: this.filter.status,
				datastart: this.date.length ? this.$moment.utc(this.date[0]).valueOf() : undefined,
				datestop: this.date.length ? this.$moment.utc(this.date[1]).endOf('day').valueOf() : undefined,
			};
			await this.GET_CAMPAIGN_LIST(data);
		},
		async refresh() {
			this.filter = {
				group: undefined,
				status: 'all',
				date: [],
			};
			await this.getData();
		},
		async getData() {
			let data = {
				group: this.filter.group,
				status: this.filter.status
			};
			await this.GET_CAMPAIGN_LIST(data);
		},
		save(date) {
			this.$refs.menu.save(date);
		},
		test(event, item) {
			let isButtonClick = false;
			let path = this.eventPath(event);
			path.forEach((a) => {
				if (a.classList) {
					if (a.classList.contains('v-data-table-header')) {
						isButtonClick = true;
					}
				}
			});
			if (isButtonClick) item.show = !item.show;
		},
		counter(data) {
			return data.length;
		},
		getNestedValue(o, s) {
			s = s.replace(/\[(\w+)\]/g, '.$1');
			s = s.replace(/^\./, '');
			let a = s.split('.');
			for (let i = 0, n = a.length; i < n; ++i) {
				let k = a[i];
				if (k in o) {
					o = o[k];
				} else {
					return;
				}
			}
			return o;
		},
		summary(scope, data) {
			let result = data
			.map((a) => {
				return this.getNestedValue(a, scope);
			})
			.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue));
			result = this.formatNumber(result);
			return result === 0 ? '-' : result;
		},
		async getStatusSurface() {
			try {
				const data = this.getTableData.flatMap(item => item.data)
				const entries = Object.values(data);
				entries.forEach(value => {
					this.campaignIDs.push(value.id);
				});
				await this.GET_INVENTORY_STATUS({campaignIDs: this.campaignIDs})
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'Ошибка статуса поверхностей',
					text: e.message || 'Ошибка в получении статуса поверхностей',
				});
			}
		},
		statusInfo(id) {
			if (Object.keys(this.getInventoryStatus).find(item => item === id)) {
				return true
			}
		},
		limitTotalBudget(id) {
			return this.getCampaigns.some(item => item.state && item.state.budget && item.state.budget >= item.limit.totalBudget && item.id === id)
		},
		limitDayBudget(id) {
			return this.getCampaigns.some(item => item.state && item.state.dayBudget && item.state.dayBudget >= item.limit.dayBudget && item.id === id)
		}
	},
};
</script>

