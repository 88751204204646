<template lang="pug">
  div
    v-dialog(v-model='archiveDialog', width='900')
      template(v-slot:activator='{ on, attrs }')
        tooltip(text='Архивировать кампанию')
          v-btn.ml-2(
            elevation='0',
            outlined,
            fab,
            v-bind='attrs',
            v-on='on',
            x-small,
            @click='archiveDialog = true',
            color='primary'
          )
            v-icon(small) mdi-archive-plus
      v-card(elevation='0')
        v-card-title.headline.grey.lighten-2
          span Подтвердить архивирование рекламной кампании?
          v-alert.mt-4.mb-0(type='warning', dense, text)
            .fz-13 Архивированная рекламная кампания не отображается в общем списке, статистика исчезнет из общей информации о группе
        v-card-actions.pb-3
          v-spacer
          v-btn(
            @click='archiveDialog = false',
            elevation='0',
            outlined,
            color='accent'
          ) Отмена
          v-btn.ml-4(
            @click='archiveCampaign',
            elevation='0',
            color='red'
          )
            .white--text Архивировать
</template>

<script>
import {mapActions} from 'vuex';
import Tooltip from '@/components/tooltip';
import loading from '@/mixins/loading_mixin';
import {SET_CAMPAIGN_ARCHIVE} from '@/store/const/campaign';

export default {
  name: 'archive',
  components: {Tooltip},
  mixins: [loading],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      archiveDialog: false,
    }
  },
  methods: {
    ...mapActions('Campaign', [SET_CAMPAIGN_ARCHIVE]),
    async archiveCampaign() {
      await this.SET_CAMPAIGN_ARCHIVE({
        id: this.id,
      });
      if (this.isError(SET_CAMPAIGN_ARCHIVE)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось заархивировать кампанию',
        });
        return;
      }
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: 'Кампания успешно помещена в архив',
      });
      this.$emit('refresh')
      this.archiveDialog = false;
    }
  }
}
</script>

<style scoped>
.fz-13 {
  font-size: 13px;
}
</style>