<template lang="pug">
  div
    v-dialog(v-model='dialog', width='900')
      template(v-slot:activator='{ on, attrs }')
        tooltip(text='Копировать')
          v-btn.ml-2(fab, x-small, v-bind='attrs', v-on='on', outlined, color='primary')
            v-icon
              | mdi-content-copy
      v-card(v-model='dialog')
        v-card-title.headline.grey.lighten-2
          div Копировать "{{ name }}"
        v-card-text.mt-5
          v-text-field(label='Новое название', v-model='newName', dense, outlined, hide-details)
          v-checkbox(label='Копировать креативы', color='accent', v-model='withCreatives', hide-details)
          .mt-5(style='text-align: right')
            v-btn(@click='copy', elevation='0', color='accent')
              | Копировать
</template>

<script>
import {mapActions} from 'vuex';
import loading from '@/mixins/loading_mixin';
import Tooltip from '@/components/tooltip';
import {COPY_CAMPAIGN} from '@/store/const/campaign';

export default {
  name: 'Copy',
  components: {Tooltip},
  mixins: [loading],
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      newName: '',
      dialog: false,
      withCreatives: false,
    };
  },
  methods: {
    ...mapActions('Campaign', [COPY_CAMPAIGN]),
    async copy() {
      if (!this.validation()) return;
      let data = {
        name: this.newName,
        id: this.id,
        withCreatives: this.withCreatives,
      };
      await this.COPY_CAMPAIGN(data)
      if (this.isError(COPY_CAMPAIGN)) {
        this.errorShow(this.errorMessage(COPY_CAMPAIGN));
        return
      }
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: 'Кампания успешно скопирована',
      });
      this.$emit('update');
      this.dialog = false;
    },
    validation() {
      if (!this.newName) {
        this.errorShow('Пустое название кампании');
        return false;
      }
      if (this.newName.length < 4) {
        this.errorShow('Название должно быть более 3 знаков');
        return false;
      }
      return true;
    },
    errorShow(message) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: message,
      });
    },
  },
};
</script>

<style scoped></style>
